<template>
  <div id="data-view">
    <dv-full-screen-container>

      <top-header />

      <div class="main-content">
        <!-- <digital-flop /> -->
		<!-- 产线信息 & 产线布局 -->
		<div style="display: flex;height: 20%;">
			<div style="flex: 2;">
				<dv-border-box-1>
					<div style="width: 92%;padding-left: 20px;padding-top: 2%;height: 88%;">
						
						<div style="font-weight: bold;height: 25%;display: flex;align-items: center;font-size: 20px;">产线信息</div>
						<div style="display: flex;height: 73%;">
							<div style="flex: 1;">
								<div style="text-align: left;font-size: 13px;height: 20%;">
									Customer(客户)：AAM(福特)
								</div>
								<div style="text-align: left;font-size: 13px;height: 20%;">
									Product(品名)：Diff-case(差壳)
								</div>
								<div style="text-align: left;font-size: 13px;height: 20%;">
									Peak Volume(峰值年量)：130,000 pieces/year
								</div>
								<div style="text-align: left;font-size: 13px;height: 20%;">
									Ship to(发运地)：Thailand Plant(泰国工厂)
								</div>
								<div style="text-align: left;font-size: 13px;height: 20%;">
									Platform(车型)：Ford Ranger
								</div>
							</div>
							<div style="flex: 1;display: flex;">
								<div id="car-img" style="width: 45%;height: 100%;">
									
								</div>
								<div id="item-img" style="width: 45%;height: 100%;margin-left: 5%;">
									
								</div>
							</div>
						</div>
					</div>
				</dv-border-box-1>
			</div>
			<div style="flex: 1;">
				<dv-decoration-11>
					<dv-decoration-6 style="width:90%;height:50%;" />
				</dv-decoration-11>
			</div>
			<div style="flex: 2;">
				<dv-border-box-1>
					<div style="width: 92%;height: 85%;padding-left: 4%;padding-right: 4%;padding-top: 2%;">
						<div id="cxbj" style="width: 100%;height: 100%;"></div>
					</div>
				</dv-border-box-1>
			</div>
		</div>
		
		<div >
			<div style="font-weight: bold;height: 50px;display: flex;align-items: center;font-size: 20px;">生产信息</div>
			
			<div style="display: flex;">
				<div style="flex: 1;">
					<div style="display: flex;">
						<div style="flex: 8;">
							昨日生产效率统计
						</div>
						<div style="flex: 2;">
							<div>
								标准产能：160
							</div>
							<div>
								生产效率目标：90%
							</div>
						</div>
					</div>
				</div>
				<div style="flex: 1;">
					
				</div>
			</div>
			<div style="display: flex;">
				<div style="flex: 1;height: 300px;">
					<dv-scroll-board :config="config" />
				</div>
				<div style="flex: 1;height: 300px">
					<dv-charts style="height: 100%;width: 100%;" :option="option1" />
				</div>
			</div>
		</div>
		<!-- 不良信息 -->
		<div>
			<div style="font-weight: bold;height: 50px;display: flex;align-items: center;font-size: 20px;">不良信息</div>
			
			<div style="display: flex;">
				<div style="flex: 1;">
					<div style="display: flex;">
						<div style="flex: 8;">
							2024年质量统计
						</div>
						<div style="flex: 2;">
						</div>
					</div>
				</div>
				<div style="flex: 1;">
					
				</div>
			</div>
			<div style="display: flex;">
				<div style="flex: 1;height: 300px;">
					<dv-scroll-board :config="config2" />
				</div>
				<div style="flex: 1;height: 300px;">
					<dv-charts style="height: 100%;width: 100%;" :option="option2" />
				</div>
			</div>
		</div>
        <!-- <div class="block-left-right-content">
          <ranking-board />

          <div class="block-top-bottom-content">
            <div class="block-top-content">
              <rose-chart />

              <water-level-chart />

              <scroll-board />
            </div>

            <cards />
          </div>
        </div> -->
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import topHeader from './topHeader'
import digitalFlop from './digitalFlop'
import rankingBoard from './rankingBoard'
import roseChart from './roseChart'
import waterLevelChart from './waterLevelChart'
import scrollBoard from './scrollBoard'
import cards from './cards'

export default {
  name: 'DataView',
  components: {
    topHeader,
    digitalFlop,
    rankingBoard,
    roseChart,
    waterLevelChart,
    scrollBoard,
    cards
  },
  data () {
    return {
		config: {
			header: ['时间', '1', '2','3','4','5','6','7','8','9','10','11','12','Total', 'Uptime%'],
			data: [
				['1线计划', '12', '13','14','13','14','13','14','13','14','13','14','l3', '160', '100%'],
				['1线实际', '12', '13','14','13','14','13','14','13','14','13','14','l3', '160', '100%'],
				['2线计划', '12', '13','14','13','14','13','14','13','14','13','14','l3', '160', '100%'],
				['2线实际', '12', '13','14','13','14','13','14','13','14','13','14','l3', '160', '100%'],
			],
			align: [
				  'left',
				  'center',
				  'center',
				  'center',
				  'center',
				  'center',
				  'center',
				  'center',
				  'center',
				  'center',
				  'center',
				  'center',
				  'center',
				  'center',
				  'center',
			],
			// columnWidth: [
			// 	  80,
			// 	  40,
			// 	  40,
			// 	  40,
			// 	  40,
			// 	  40,
			// 	  40,
			// 	  40,
			// 	  40,
			// 	  40,
			// 	  40,
			// 	  40,
			// 	  40,
			// 	  40,
			// 	  100,
			// ]
		},
		config2: {
			header: ['周', '生产量', '不良品','工废率'],
			data: [
				['W31', '3041', '29','0.95%'],
				['W32', '3255', '34','1.04%'],
				['W33', '3309', '38','1.15%'],
				['W34', '3171', '39','1.23%'],
				['W35', '3190', '35','1.10%'],
				['W36', '3220', '32','0.99%'],
				['W37', '2806', '30','1.07%'],
				['W37', '2806', '30','1.07%'],
				['W37', '2806', '30','1.07%'],
				['W37', '2806', '30','1.07%'],
				['W37', '2806', '30','1.07%'],
				['W37', '2806', '30','1.07%'],
			],
			// rowNum: 5,
		},
		option1: {
			title: {
			    text: '昨日生产效率图表',
				style: {
					fill: '#fff',
				}
			  },
			  xAxis: {
			    data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
				nameTextStyle: {
					fill: '#fff'
				},
				axisLabel: {
					style: {
						fill: '#fff'
					}
				}
			  },
			  yAxis: {
			    data: 'value',
				nameTextStyle: {
					fill: '#fff'
				},
				axisLabel: {
					style: {
						fill: '#fff'
					}
				},
				min: 0,
				interval: 5,
			  },
			  legend: {
				  show: true,
				  textStyle: {
					  fill: '#fff',
				  },
				  data: [
					  {
						  name: '1线计划',
					  },
					  {
						  name: '1线实际',
						  // color: 'orange'
					  },
					  {
						  name: '2线计划',
						  // color: 'gray',
					  },
					  {
						  name: '2线实际',
						  // color: 'yellow'
					  },
				  ]
			  },
			  series: [
			    {
			      data: [12, 13, 14, 15, 13, 14, 15, 13, 15, 14, 13, 12],
			      type: 'bar',
				  color: '',
				  name: '1线计划',
				  label: {
					  show: true,
				  },
					animationCurve: 'easeOutBounce'
			    },
			    {
			      data: [12, 13, 14, 15, 13, 14, 15, 13, 15, 14, 13, 12],
			      type: 'bar',
				  // color: 'orange',
				  name: '1线实际',
				  label: {
					  show: true,
				  },
					animationCurve: 'easeOutBounce'
			    },
			    {
			      data: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
			      type: 'bar',
				  // color: 'gray',
				  name: '2线计划',
				  label: {
					  show: true,
				  },
					animationCurve: 'easeOutBounce'
			    },
			    {
			      data: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
			      type: 'bar',
				  // color: 'yellow',
				  name: '2线实际',
				  label: {
					  show: true,
				  },
					animationCurve: 'easeOutBounce'
			    }
			  ]
		},
		
		option2: {
			title: {
			    text: '2024工废率变化趋势',
				style: {
					fill: '#fff',
				}
			  },
			  xAxis: {
			    data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
				nameTextStyle: {
					fill: '#fff'
				},
				axisLabel: {
					style: {
						fill: '#fff',
						rotate: 20,
						textAlign: 'left',
						textBaseline: 'top'
					}
				},
				axisTick: {
				  show: false
				}
			  },
			  yAxis: [
				  {
					  name: '不良品',
					  nameTextStyle: {
						  fill: '#fff',
					  },
					  data: 'value',
					  axisLabel: {
						  style: {
							  fill: '#fff',
						  }
					  },
					  min: 0,
					  interval: 5,
						splitLine: {
						  style: {
							lineDash: [3,3]
						  }
						},
					  axisTick: {
						show: false
					  }
				  },
				  {
					  name: '工废率',
					  nameTextStyle: {
						  fill: '#fff',
					  },
					  data: 'value',
					  position: 'right',
					  axisLabel: {
						  formatter: `{value} %`,
						  style: {
							  fill: '#fff',
						  }
					  },
					  min: 0,
					  max: 10,
					  interval: 3,
						splitLine: {
						  show: false
						},
						axisTick: {
						        show: false
						      }
				  }
			  ],
			  legend: {
				  show: true,
				  textStyle: {
					  fill: '#fff',
				  },
				  data: [
					  {
						  name: '不良品',
					  },
					  {
						  name: '工废率',
						  // color: 'orange'
					  },
				  ]
			  },
			  series: [
			    {
			      data: [1, 3, 4, 5, 2, 10, 2, 3, 1, 3, 3, 1],
			      type: 'bar',
				  color: '',
				  name: '不良品',
				  label: {
					  show: true,
				  },
				  gradient: {
					  color: ['#37a2da', '#67e0e3']
					},
					animationCurve: 'easeOutBounce'
			    },
			    {
			      data: [0.95, 1.04, 1.15, 1.23, 1.01, 0.99, 1.07, 1.06, 0.93, 1.25, 1.27, 1.27],
			      type: 'line',
				  // color: 'orange',
				  name: '工废率',
				  label: {
					  show: true,
				  },
				  yAxisIndex: 1,
				  animationCurve: 'easeOutBounce'
			    },
			  ]
		}
	}
  },
  created() {
  },
  methods: {}
}
</script>

<style lang="less">
#data-view {
  width: 100%;
  height: 100%;
  background-color: #030409;
  color: #fff;

  #dv-full-screen-container {
    background-image: url('./img/bg.png');
    background-size: 100% 100%;
    box-shadow: 0 0 3px blue;
    display: flex;
    flex-direction: column;
  }
  
  #car-img {
	background-image: url('./img/car.jpg');
    background-size: 100% 100%;
	  
  }
  
  #item-img {
	background-image: url('./img/item.jpg');
    background-size: 100% 100%;
  }
  
  #cxbj {
	  background-image: url('./img/cxbj.png');
	  background-size: 100% 100%;
  }

  .main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .block-left-right-content {
    flex: 1;
    display: flex;
    margin-top: 20px;
  }

  .block-top-bottom-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-left: 20px;
  }

  .block-top-content {
    height: 55%;
    display: flex;
    flex-grow: 0;
    box-sizing: border-box;
    padding-bottom: 20px;
  }
}
</style>
